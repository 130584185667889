<script>
import Layout from "@/router/layouts/franchise";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
  },
  data() {
    return {
      account: null,
    };
  },
  methods: {
    getAccount() {
      api.get("franchise/account").then((response) => {
        if (response.data.status == "success") {
          this.account = response.data.account;
        }
      });
    },
  },
  mounted() {
    this.getAccount();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Meu PDV") }}</h4>
        </div>
      </div>
    </div>
    <div class="row" v-if="account">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pb-2">
            <div class="row mb-2">
              <div
                class="col-sm-12 col-md-4 align-self-center text-md-right pr-3 text-uppercase font-weight-bold font-size-11 text-middle"
              >
                Nome da Franquia
              </div>
              <div class="col-sm-12 col-md-8 align-self-center">
                {{ account.name }}
              </div>
            </div>
            <div class="row mb-2">
              <div
                class="col-sm-12 col-md-4 align-self-center text-md-right pr-3 text-uppercase font-weight-bold font-size-11 text-middle"
              >
                Responsável
              </div>
              <div class="col-sm-12 col-md-8 align-self-center">
                {{ account.responsible }}
              </div>
            </div>
            <div class="row mb-2">
              <div
                class="col-sm-12 col-md-4 align-self-center text-md-right pr-3 text-uppercase font-weight-bold font-size-11 text-middle"
              >
                Telefone
              </div>
              <div class="col-sm-12 col-md-8 align-self-center">
                {{ account.phone }}
              </div>
            </div>
            <div class="row mb-2">
              <div
                class="col-sm-12 col-md-4 align-self-center text-md-right pr-3 text-uppercase font-weight-bold font-size-11 text-middle"
              >
                E-mail
              </div>
              <div class="col-sm-12 col-md-8 align-self-center">
                {{ account.email }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pb-2">
            <div class="row mb-2">
              <div
                class="col-sm-12 col-md-4 align-self-center text-md-right pr-3 text-uppercase font-weight-bold font-size-11 text-middle"
              >
                CNPJ
              </div>
              <div class="col-sm-12 col-md-8 align-self-center">
                {{ account.company.cnpj }}
              </div>
            </div>
            <div class="row mb-2">
              <div
                class="col-sm-12 col-md-4 align-self-center text-md-right pr-3 text-uppercase font-weight-bold font-size-11 text-middle"
              >
                Razão Social
              </div>
              <div class="col-sm-12 col-md-8 align-self-center">
                {{ account.company.name }}
              </div>
            </div>
            <div class="row mb-2">
              <div
                class="col-sm-12 col-md-4 align-self-center text-md-right pr-3 text-uppercase font-weight-bold font-size-11 text-middle"
              >
                Endereço
              </div>
              <div class="col-sm-12 col-md-8 align-self-center">
                {{ account.company.address }}, {{ account.company.number
                }}<br />
                <template v-if="account.company.complement"
                  >{{ account.company.complement }},
                </template>
                {{ account.company.district }}, {{ account.company.city }}/{{
                  account.company.state
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
